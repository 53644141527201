// window.$authCode = 'df243a2a-6feb-43f9-a599-ae100314d961' || 'mock';
// window.$ditappVersion = 'v2.1.18';
// window.$ditappBaseUrl = 'web page base url'
// window.$ditappPluginRoot = 'https://plugins.central1.cc/T3DDUCA/duca-moreaccount/canary';
// window.$ditappEndpoint = 'https://qa.rewards.duca.com';

export const ROOT_URL = window.$ditappEndpoint || '';

export const OPTIONS = {
    mode: 'cors',
    method: 'GET',
    headers: {
        'Accept': 'application/json',
    },
    credentials: 'include'
};
