import React from "react";

function Home() {

    return (<React.Fragment>
        <div style={{margin: 'auto'}}>
            <h1 style={{marginTop: '50px'}}>This is a test page, to verify the server is up and running</h1>
        </div>
    </React.Fragment>)
}
export default Home;
