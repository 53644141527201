import React, { useEffect, useState } from "react";
import { OPTIONS, ROOT_URL } from "../constants";

function SliderBar({ className, value, onChange }) {
    return (
        <React.Fragment>
            <div className='w-100 d-flex' style={{ justifyContent: 'space-between' }}>
                <div>
                    {value.label}
                </div>
                <div>
                    {new Intl.NumberFormat('en-CA', {
                        style: 'currency',
                        currency: 'CAD',
                        maximumFractionDigits: 0
                    }).format(value.currentValue)}
                </div>
            </div>
            <input type="range" min={value.min} max={value.max} step={value.step} value={value.currentValue}
                onChange={(e) => {
                    onChange(e.target.value)
                }}
                style={{ width: '100%', accentColor: '#1178FE' }}></input>
            <div className={'w-100 d-flex ' + className}
                style={{ justifyContent: 'space-between', accentColor: 'var(--bs-primary-1)' }}>
                <div>
                    {new Intl.NumberFormat('en-CA', {
                        style: 'currency',
                        currency: 'CAD',
                        maximumFractionDigits: 0
                    }).format(value.min)}
                </div>
                <div>
                    {new Intl.NumberFormat('en-CA', {
                        style: 'currency',
                        currency: 'CAD',
                        maximumFractionDigits: 0
                    }).format(value.max)}
                </div>
            </div>
        </React.Fragment>
    );
}

function ProductValue({ value }) {
    return (
        <b> {new Intl.NumberFormat('en-CA', {
            style: 'currency',
            currency: 'CAD',
            maximumFractionDigits: 0
        }).format(value.currentValue)} {value.label}</b>
    )
}

function EarnRewards() {

    const [data, setData] = useState({
        products: [],
        maxReward: 1000,
        rate: 0.01
    });
    const [values, setValues] = useState({});
    const [reward, setReward] = useState(0);

    const getData = () => {
        console.log("getData");
        fetch(`${ROOT_URL}/api/calc/data`, OPTIONS)
            .then(response => response.json())
            .then(myJson => {
                console.log("data", myJson);
                setData(myJson.data);
            });
    }

    useEffect(() => {
        getData()
    }, [])

    useEffect(() => {
        const initValues = {};
        console.log("data", data);
        data.products.forEach((r, i) => {
            initValues[r.name] = {
                ...r,
                index: i,
                currentValue: 0,
            }
        });
        console.log("initValues", initValues);
        setValues(initValues);
    }, [data])

    useEffect(() => {
        const newResult = Math.round(calculateReward(values));
        if (newResult >= data.maxReward) {
            setReward(data.maxReward);
        } else {
            setReward(newResult);
        }
    }, [values])
    const calculateReward = (r) => {
        let result = 0;
        console.log("calculateReward", r);
        Object.keys(r).forEach(k => {
            const v = r[k];
            result += v.rate * v.currentValue;
        });
        result = result * data.rate;
        return result;
    }

    const handleRewardsOnChange = (type, value) => {
        setValues((state) => {
            const result = { ...state };
            result[type].currentValue = value;
            return result;
        });
    }

    const valuesSortByOrder = () => {
        let sorted = Object.keys(values).map(k => values[k]).sort(r => r.index);
        console.log("sorted", sorted);
        return sorted;
    }

    function renderProductValue(v) {
        let label = ", a";
        if (v.index === 0) {
            label = " a"
        } else if (v.index === data.products.length - 1) {
            label = " and"
        }
        return <React.Fragment>
            {label} <ProductValue key={v.index} value={v} />
        </React.Fragment>
    }

    return (<React.Fragment>
        <div>
            <div className='d-flex flex-wrap'>
                <div className="col-lg-7 col-md-6 col-sm-12 mb-2 py-2 pe-2">
                    <h1 className="md-card-header-color mb-1 md-collapsable-card-header">
                        Do More Profit Sharing rewards calculator</h1>
                    <p>
                        Here’s where you can estimate how being a valued DUCA Member can earn annual profit-share rewards for you.
                        Adjust the sliders below to reflect the average yearly balance of your DUCA products in each category.
                        Watch the calculator to the right for an estimate of your profit-share rewards.
                    </p>
                    <p>
                        Then, once earned, you can apply profit-share rewards towards even better deposit or mortgage/loan rates.
                    </p>
                    <p>
                        Want to reach your financial goals sooner?
                        Talk to one of our experts to see how earning profit-share rewards can help you reach your goals and put more money in your pocket.
                    </p>
                    {valuesSortByOrder().map((d, i) =>
                        <SliderBar key={i} className="mb-2" value={d}
                            onChange={(value) => handleRewardsOnChange(d.name, value)} />
                    )}
                </div>
                <div className="col-lg-5 col-md-6 col-sm-12 mb-2 py-2 px-1" style={{ backgroundColor: "#F1F1F1" }}>
                    <div className="w-100">
                        <h1 className="md-card-header-color mb-1 md-collapsable-card-header">
                            My result</h1>
                        <p>
                            Based on the amount you selected on;
                            {valuesSortByOrder().map(v => renderProductValue(v))},
                            the annual eligibility of profit-share rewards you could receive based on a full calendar
                            year is...
                        </p>

                        <div className="d-flex mb-2" style={{
                            display: "flex",
                            flexDirection: "column",
                            alignContent: "left",
                            justifyContent: "center",
                            textAlign: "left"
                        }}>
                            <div className="me-2">
                                <h1 style={{
                                    fontSize: '50px',
                                    display: "flex",
                                    flexDirection: "row",
                                    alignItems: "center",
                                    textAlign: "left"
                                }}>


                                    {new Intl.NumberFormat('en-CA', { maximumFractionDigits: 0 }).format(reward)}

                                    <p style={{
                                        display: "flex",
                                        fontSize: '30px',
                                        justifyContent: "center",
                                        alignContent: "center",
                                        marginTop: "20px",
                                        marginLeft: "10px"
                                    }}> profit-share rewards </p>

                                </h1>


                            </div>
                        </div>

                        <p>
                            <i style={{ marginRight: '4px' }}><b>Already have profit-share rewards?</b></i>Great, just add
                            <span style={{ color: 'var(--bs-primary-2)', marginLeft: '3px', marginRight: '3px' }}><b>
                                {new Intl.NumberFormat('en-CA', { maximumFractionDigits: 0 }).format(reward)}</b>
                            </span>

                            to your current profit-share rewards balance to get your new projected amount.
                            <br></br>
                            <br></br>

                            Each calendar year, you can earn a maximum of 1,000 profit-share rewards based on your
                            relationship with DUCA

                        </p>


                        <img alt="placeHolder"
                            style={{ width: '100%', height: 'auto', minHeight: '250px', objectFit: 'cover' }}
                            src="https://www.duca.com/assets/img/images/OLBimages/Desktop/OLB_image1.jpg" />
                    </div>
                </div>
            </div>
            <p style={{ fontSize: '10px', textAlign: 'justify' }}>
                Disclaimer: This calculator is for informational and illustrative purpose only and is not intended to be
                a substitute for professional advice.
                This illustration calculator is based on a 1% profit sharing rate and is subject to change on a yearly
                basis.
                Your total rewards amount may vary. Investments excludes Wealth products with Qtrade and Credential.
                The calculation is dependent on the accuracy and completeness of the data you enter and is not
                guaranteed.
                Rewards could be paid differently on different types of accounts. Individual circumstances may differ;
                a representative from DUCA Credit Union can help you determine your eligibility for rewards and how to
                enhance your rate of return.
                Investments at DUCA Credit Union include GICs and term deposits in both registered and non-registered
                accounts.
                Rewards figures shown in this calculator are based on the previous DUCA credit Union rewards Program
                model as approved by the DUCA.
            </p>
        </div>
    </React.Fragment>)
}

export default EarnRewards;
