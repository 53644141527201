import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import './asset/styles/online_v2.css';
import { HashRouter, Route, Routes } from 'react-router-dom';
import EarnRewards from "./components/earn-rewards";
import Home from "./components/home";

function App() {

    return (
        <HashRouter>
            <React.Suspense fallback={<p>Loading...</p>}>
                <Routes>
                    <Route exact path='/' element={< Home />}></Route>
                    <Route exact path='/rewards-calculator' element={< EarnRewards />}></Route>
                </Routes>
            </React.Suspense>
        </HashRouter>
    );
}

export default App;
